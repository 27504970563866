import { axiosConfig } from './axios-config.js'

// 機構設定
export const agency = {
  // 取得夜間睡眠時間
  getSleepingTime: token => axiosConfig.get('/api/sleeping_time', token),

  // 更新夜間睡眠時間
  updateSleepingTime: (data, token) => axiosConfig.put('/api/sleeping_time', data, token),

  // 取得通知清單
  getNotification: token => axiosConfig.get('/api/notification', token),

  // LINE 推播 登入
  lineOauth: token => axiosConfig.get('/api/line/oauth', token),

  // LINE 登入後 token 登錄系統
  lineIssue: (data, token) => axiosConfig.post('/api/line/issue', data, token),

  // 裝置監控
  getBedMonitoring: (token) => axiosConfig.get('/api/bed-status', token),

  // 取得統計圖表條件
  getReportSetting: token => axiosConfig.get('/api/agency/report_setting', token),

  // 更新統計圖表條件
  updateReportSetting: (data, token) => axiosConfig.patch('/api/agency/report_setting', data, token),

  // 統計圖表
  getReport: (data, token) => axiosConfig.get('/api/agency/report', data, token),

  // 聯絡我們
  contact: (data, token) => axiosConfig.post('/api/contact', data, token)

}
